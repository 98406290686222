import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";
import getSymbolFromCurrency from 'currency-symbol-map';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid (C1)',
    'Subid (C2)',
    'Subid (C3)',
    'Campaign Category',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid (C1)': 'c1',
    'Subid (C2)': 'c2',
    'Subid (C3)': 'c3',
    'Campaign Category': 'campaign_category_name',
};

export const transformNames = [
  'currency',
  'geo',
  'affiliate_name',
  'mid_name',
  'funnel_name',
  'c1',
  'c2',
  'c3',
  'campaign_category_name',
];

export const fields = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_with_orders",
    "Partials": "partials",
    "Partials %": "partials_percentage",
    "Declines": "declines",
    "Declines %": "declines_percentage",
    "Sales": "sales",
    "Conv %": "conv_percentage",
    "Partial Conv %": "partial_conv",
    "Sales %": "sales_conv",
    "Sales Rev": "sales_rev",
} as const;

export const fieldsForCSV = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_with_orders",
    "Partials": "partials",
    "Partials %": "partials_percentage",
    "Declines": "declines",
    "Declines %": "declines_percentage",
    "Sales": "sales",
    "Conv %": "conv_percentage",
    "Partial Conv %": "partial_conv",
    "Sales %": "sales_conv",
    "Sales Rev": "sales_rev",
};

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];


export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D')},
    4: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('D') },
    5: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
    6: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('D') },
    7: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().startOf('w').subtract(1, "s") },
    8: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
        3: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, 
        5: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        7: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().startOf('week').subtract(1, "second").tz(timezone) },
        8: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
        9: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
};

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(300 + ((Number(groupingsCount) + 1) * 8)) - ((row.depth + 1) * 40)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.clicks,
            id: 'Clicks',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Clicks',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.clicks_with_orders,
            id: 'Order Clicks',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Order Clicks',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partials,
            id: 'Partials',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Partials',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partials_percentage,
            id: 'Partials %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Partials %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Partials / (Partials + Declines + Sales)'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.declines,
            id: 'Declines',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Declines',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.declines_percentage,
            id: 'Declines %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Declines %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Declines / (Declines + Sales)'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.sales,
            id: 'Sales',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Sales',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.conv_percentage,
            id: 'Conv %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Conv %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Sales / Clicks * 100'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partial_conv,
            id: 'Partial Conv %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Partial Conv %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Sales / (Partials + Declines + Sales)'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.sales_conv,
            id: 'Sales %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Sales %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Sales / (Sales + Declines)'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.sales_rev,
            id: 'Sales Rev',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Sales Rev',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
        ]
}