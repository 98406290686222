import getSymbolFromCurrency from 'currency-symbol-map';
import { RetentionReportData, TransactionReportRowInterface } from '../types/retention-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function transformDataToCSVFormat(
  data: RetentionReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined
) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = ['Currency', ...filters!] 

  function processItem(item: TransactionReportRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = {
      cancels: parseFloat(item.cancels).toFixed(2) + ' %' || '0.00',
      chargebacks: item.chargebacks || '0',
      rdr: item.rdr || '0',
      alerts: item.alerts || '0',
      ethoca_alerts: item.ethoca_alerts || '0',
      collab_alerts: item.collab_alerts || '0',
      gross_revenue: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.gross_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      partial_refund: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.partial_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      full_refund: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.full_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      net: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.net?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '')
    };

    if (item.cycles) {
      baseItem['Cycles 2+ Pending Orders'] = item.cycles.pending_orders?.toLocaleString() || '0';
      baseItem['Cycles 2+ Rebill Schedule Is Success'] = item.cycles.rebill_schedule_is_success?.toLocaleString() || '0';
      baseItem['Cycles 2+ Declined'] = item.cycles.declined?.toLocaleString() || '0';
      baseItem['Cycles 2+ Recycle Success'] = item.cycles.recycle_success?.toLocaleString() || '0';
      baseItem['Cycles 2+ Recycle Failed'] = item.cycles.recycle_failed?.toLocaleString() || '0';
      baseItem['Cycles 2+ Chargebacks'] = item.cycles.retention_chargebacks || '0';
      baseItem['Cycles 2+ RDR'] = item.cycles.retention_rdr || '0';
      baseItem['Cycles 2+ All Alerts'] = item.cycles.retention_alerts || '0';
      baseItem['Cycles 2+ Retention Ethoca Alerts'] = item.cycles.retention_ethoca_alerts || '0';
      baseItem['Cycles 2+ Retention Collab'] = item.cycles.retention_collab_alerts || '0';
      baseItem['Cycles 2+ Gross Revenue'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_gross_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Partial Refund'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_partial_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Full Refund'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_full_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Net'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_net?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
    }

    if (item.total) {
      baseItem["Total Avg LTV"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.avgLtv?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
      baseItem["Total Gross Revenue"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.grossRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
      baseItem["Total Net Revenue"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.netRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
    }

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`${filtersList[level]}`] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: TransactionReportRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    // @ts-ignore
    data.currency.forEach((currencyItem: DataItem) => processItem(currencyItem));
  }

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `${filtersList[level]}`
  );

  return result.map((item) => {
    const transformedItem: { [key: string]: string } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    Object.keys(item).forEach((key) => {
      if (!groupingFields.includes(key) && !Object.keys(fieldsForCSV).includes(key)) {
        transformedItem[key] = item[key];
      }
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;
