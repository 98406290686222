import { ChargebackSummaryReportData } from "../types/chargeback-summary.types";


function transformData(input: ChargebackSummaryReportData[], categoryKey: string): any {
    const result = input.map((gateway: ChargebackSummaryReportData) => {
      let totalCount = 0;
      gateway.chargeback_reason_codes.forEach(reason => {
        const countNumber: number = typeof reason.count === 'string' ? parseInt(reason.count, 10) : reason.count;
        totalCount += countNumber;
      });
  
  
      return {
        [categoryKey]: gateway.title,
        count: totalCount,
        subCategories: [...gateway.chargeback_reason_codes]
      };
    });

    return result
}

export default transformData;