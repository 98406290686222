import { FraudAnalysisReportData, StatsInterface } from "../types/fraud-analysis-report.types";
import { transformNames } from "./config";
import { v4 as uuidv4 } from 'uuid';

export const prepareDataForTransform = (data: any) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value)
              .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
              .map(
                ([nestedKey, nestedValue]) => {
                  if (nestedKey === "") {
                    return transformObject(nestedValue, "NaN");
                  }
                  return transformObject(nestedValue, nestedKey);
                }
              );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue)
          .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
          .map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
      }
    );
    return result;
};

export function transformData(data: any): FraudAnalysisReportData[] {
    const preparedData = prepareDataForTransform(data);
    function recursiveTransform(node: any): FraudAnalysisReportData[] {
        if (Array.isArray(node)) {
        return node.flatMap(item => recursiveTransform(item));
        } else if (typeof node === 'object' && node !== null) {
        const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

        if (nameKey) {
            const name = node.name ?? '---';

            const stats: StatsInterface = {
              name,
              sales: node.sales || null,
              number_of_alerts: node.number_of_alerts || null,
              number_of_high_risk_orders: node.number_of_high_risk_orders || null,
              number_of_transactions: node.number_of_transactions || null,
              number_of_sales: node.number_of_sales || null,
              number_of_main_offer_sales: node.number_of_main_offer_sales || null,
              number_of_upsell_sales: node.number_of_upsell_sales || null,
              number_of_initial_transactions: node.number_of_initial_transactions || null,
              number_of_rebill_transactions: node.number_of_rebill_transactions || null,
              number_of_reproc_transactions: node.number_of_reproc_transactions || null,
              percentage_of_fraud_alerts_of_all_alerts: node.percentage_of_fraud_alerts_of_all_alerts || null,
              percentage_of_fraud_alerts_of_transactions: node.percentage_of_fraud_alerts_of_transactions || null,
              percentage_of_fraud_alerts_of_sales: node.percentage_of_fraud_alerts_of_sales || null,
              number_of_ethoca_alerts: node.number_of_ethoca_alerts || null,
              number_of_rdr_alerts: node.number_of_rdr_alerts || null,
              number_of_collab_alerts: node.number_of_collab_alerts || null,
              number_of_chargeback_alerts: node.number_of_chargeback_alerts || null,
              ethoca_alerts_to_all_transactions: node.ethoca_alerts_to_all_transactions || null,
              rdr_alerts_to_all_transactions: node.rdr_alerts_to_all_transactions || null,
              collab_alerts_to_all_transactions: node.collab_alerts_to_all_transactions || null,
              chargeback_alerts_to_all_transactions: node.chargeback_alerts_to_all_transactions || null,
            };

            const child_data: FraudAnalysisReportData[] = [];

            for (const key in node) {
              if (transformNames.includes(key) && key !== nameKey) {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              } else if (typeof node[key] === 'object') {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              }
            }

            return [{
              name,
              stats,
              child_data,
            }];
        } else {
            // Если имя не найдено или не является строкой, рекурсивно обрабатываем дочерние узлы
            const child_data: FraudAnalysisReportData[] = [];

            for (const key in node) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
            }

            return child_data;
        }
        }
        return [];
    }

    return recursiveTransform(preparedData);
}
