import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker, DatePickerToolbarProps,
  LocalizationProvider,
 PickersActionBarProps,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Calender from "../../../assets/icons/Calender";
import React, {FC, useContext, useEffect, useState} from "react";
import {
  menuClasses,
  MenuItem,
  outlinedInputClasses,
  Select,
  SelectChangeEvent,
  selectClasses,
} from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { generateRangeConfigs } from "../../../types/enums/LineChartRange";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../buttons/DefaultButton";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { UserContext } from "../../../context/UserContext/UserContext";

dayjs.extend(utc);
dayjs.extend(timezone);

interface dateTimePickerProps {
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  getFilterData: () => void;
  datesApplyButton: boolean;
  customRangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  },
  customGenerateRangeConfigs?: (timezone: string) => { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } };
  dateRangeListCustom?: string[];
  isSameDateAvailable?: boolean;
}
const DatePickerLineChart: FC<dateTimePickerProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  getFilterData,
  customGenerateRangeConfigs,
  dateRangeListCustom = [],
  isSameDateAvailable = false
}: dateTimePickerProps) => {
  const dateRangeList = dateRangeListCustom && dateRangeListCustom.length > 0 ? dateRangeListCustom : [
    "Custom Range",
    "Today",
    "Yesterday",
  ];
  const userContext = useContext(UserContext)!;
  const { timezone } = userContext;
  dayjs.tz.setDefault(timezone);

  const [firstPickerOpened, setFirstPickerOpened] = useState(false)
  const [secondPickerOpened, setSecondPickerOpened] = useState(false)

  const { themedColors, theme } = useContext(ThemeContext)!;

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const [hasChanges, setHasChanges] = useState(false);
  const [initialState, setInitialState] = useState({
    startDate,
    endDate,
    dateRange,
  });

  const handleStartDateChange = (newValue: string | Dayjs | null) => {
    if (newValue === null) {
      return;
    }

    console.log('newValue', newValue)
    console.log('endDate', endDate)

    if (newValue && (!endDate || (newValue as Dayjs).isBefore(endDate))) {
      console.log('i am here 1')
      setStartDate(newValue);
      // or startDate more than endDate
    } else if (newValue && (((newValue as Dayjs).utc().isSame((endDate as Dayjs).utc(), 'day')) || (newValue as Dayjs).isAfter(endDate))) {
      console.log('i am here 2')
      setStartDate(newValue);
      if (!isSameDateAvailable) {
        setEndDate(((newValue as Dayjs).add(1, 'day')));
      } else {
        setEndDate(((newValue as Dayjs).add(1, 'day')).subtract(1, 'second'));
      }
    } else {
      console.log('i am here 3')
      setStartDate(newValue as Dayjs);
    }
  };

  const handleEndDateChange = (newValue: string | Dayjs | null) => {
    if (newValue === null) {
      return;
    }

    if (newValue && (newValue as Dayjs).isAfter(startDate)) {
      console.log('i am here 4')
      setEndDate((newValue as Dayjs));
    } else if (newValue && (newValue as Dayjs).isSame(startDate) && !isSameDateAvailable) {
      console.log('i am here 5')
      setEndDate((newValue as Dayjs));
      setStartDate((newValue as Dayjs).subtract(1, 'day'));
    }
  };

  useEffect(() => {
    if (
      startDate !== initialState.startDate ||
      endDate !== initialState.endDate ||
      dateRange !== initialState.dateRange
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [startDate, endDate, dateRange, initialState]);

  // useEffect(() => {
  //   /*
  //   On first load
  //   If there is a positive date range, this means we set the dates now, to keep them in sync.
  //    */
  //   if (dateRange) {
  //     const { startDate, endDate } = LineChartRange[dateRange];

  //     setStartDate(dayjs(startDate));
  //     setEndDate(dayjs(endDate))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onRangeSelectionChange = (e: SelectChangeEvent<number>) => {
    const value = Number(e.target.value);
    setDateRange(value);

    // Custom date range
    if (!value) {
      return
    }

    /*
    Preset date range. Set dates now.
     */

    const { startDate, endDate } = customGenerateRangeConfigs ? customGenerateRangeConfigs(timezone)[value] : generateRangeConfigs(timezone)[value]

    setStartDate(startDate);
    setEndDate(endDate);

    setHasChanges(true);
  };

  const CustomActionBar: React.FC<PickersActionBarProps> = ({ onAccept, onCancel, onClear, actions }) => {

    // if no actions are passed, like in the desktop config of the DateTimePicker, render nothing
    if (!actions?.length) {
      return null
    }

    const onClickApply = () => {
      onAccept()
      getFilterData()

    }

    return (
        <div className="w-full row-start-3 col-span-2  flex flex-row justify-center">

          <div className={"flex flex-row flex-1 gap-2 justify-between items-center p-4"}>
            <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9" onClick={onCancel} />
            <DefaultButton disabled={false} type='button' label='Apply'  className="w-full h-9" onClick={onClickApply} />
          </div>
        </div>
    )
        ;
  };

  const CustomToolbar: React.FC<DatePickerToolbarProps<string | Dayjs>> = ({hidden, className}) => {
    if (hidden) {
      return null
    }

    // Hack to determine which picker we have. Only className is a useful prop that is allowed to pass into the component.
    const getOnClose = () => {
      if (className?.includes('first-picker')) {
        return setFirstPickerOpened
      } else if (className?.includes('second-picker')) {
        return setSecondPickerOpened
      } else {
        return () => {}
      }
    }

    const onClose = getOnClose()

    return (
        <div className={"row-start-1 col-span-2 flex justify-center"}>
          <div className={"flex flex-col items-stretch w-full"}>
            <div className={"flex flex-1 w-full justify-between items-center p-4 border-b"}  style={{ borderColor: themedColors.border }}>
              <div style={{color: themedColors.content}} className="text-xl font-bold text-[#1A1F36]">Select Date</div>
              <div onClick={() => onClose(false)} className={"cursor-pointer p-2"}>
                <CloseIcon></CloseIcon>
              </div>
            </div>
            <div className={"flex flex-1 justify-between items-center w-full p-4  border-b"}  style={{ borderColor: themedColors.border }}>
              <div className={'text-sm text-[#677C9F]'}>Data range:</div>
              <DateRangeDropDown/>
            </div>
          </div>
        </div>
    );
  };

  const DateRangeDropDown = () => {
    return  <Select
        inputProps={{MenuProps: {disableScrollLock: true}}}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {
            marginBlock: "0.5rem",
            [`& .${menuClasses.paper}`]: {
              borderRadius: "6px",
              width: 150,
            },
            [`& .${menuClasses.list}`]: {
              paddingTop: 0,
              paddingBottom: 0,
              background: themedColors.background_1,
              "& li": {
                paddingTop: "12px",
                paddingBottom: "12px",
                justifyContent: "space-between",
                fontWeight: 400,
              },
              "& li:hover": {
                background: themedColors.background_1,
              },
              "& li.Mui-selected": {
                fontWeight: 500,
                background: themedColors.background_1,
              },
            },
          },
        }}
        IconComponent={ChevronDownIcon}
        value={dateRange}
        renderValue={() => dateRangeList[dateRange]}
        sx={{
          marginRight: 2,
          [`& .${selectClasses.select}`]: {
            background: themedColors.background_1,
            color: themedColors.content,
            fontSize: "16px",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingRight: "55px !important",
            paddingTop: "11px",
            paddingBottom: "10px",
            transitionDuration: "0.3s",
            "&:focus": {
              borderRadius: "12px",
              background: themedColors.background_1,
            },
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#0079FF",
            borderWidth: 1,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: themedColors.gray,
            borderWidth: 1,
            borderRadius: 12,
          },
          "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0079FF",
            },
          },
          "& .MuiSelect-icon": {
            width: "20px",
            color: themedColors.content,
            fontSize: "20px",
            right: "23px",
            top: "11px",
          },
          "& .MuiSelect-iconOpen": {
            color: "#0079FF",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme === 'light' ? '#ACBFDF' : '#495D80',
            borderRadius: '50px',
          },
        }}
        onChange={onRangeSelectionChange}
        className={'!mr-0 mui-select-daterange-picker'}
    >
      {dateRangeList.map((range: string, index: number) => (
          <MenuItem 
            className="datarange-item-mui"
            value={index} 
            key={index} 
            style={{color: themedColors.content}}
            sx={{
              "&.MuiMenuItem-root.MuiMenuItem-root:hover": {
                backgroundColor: theme === 'light' ? "rgba(25, 118, 210, 0.08)" : "rgba(144, 202, 249, 0.16)",
              }
            }}
          >
            {range}
          </MenuItem>
      ))}
    </Select>
  }

  const handleApply = () => {
    getFilterData();

    setInitialState({ startDate, endDate, dateRange });
    setHasChanges(false);
  };

  return (
      <div className="flex gap-3 items-center flex-wrap">
        <div>
          <DateRangeDropDown />
        </div>
        {!dateRange &&
        <div className="flex gap-3 max-w-[500px]">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              desktopModeMediaQuery={'@media (min-width: 1023px)'}
              disableFuture
              value={startDate}
              onChange={handleStartDateChange}
              disableHighlightToday={true}
              open={firstPickerOpened}
              onOpen={() => setFirstPickerOpened(true)}
              onClose={() => setFirstPickerOpened(false)}
              slots={{
                openPickerIcon: Calender,
                actionBar: CustomActionBar,
                toolbar: CustomToolbar
              }}
              sx={{ fontSize: "12px"}}
              
              slotProps={{
                inputAdornment: {
                  position: "start",
                  sx: {
                    "& .MuiIconButton-root": {
                      display: "block !important",
                      visibility: "visible !important",
                    },
                    "& .MuiIconButton-root svg path": {
                      stroke: themedColors.content
                    },
                    "& .MuiInputBase-input-MuiOutlinedInput-input": {
                      marginLeft: "-8px",
                    },
                  },
                },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#0079FF",
                      fontSize: "12px !important",
                    },
                  },
                },
                desktopPaper: {
                  sx: {
                    ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:focus":
                      {
                        backgroundColor: "#0079FF",
                        fontSize: "12px !important",
                      },
                  },
                },
                textField: {
                  sx: {
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                      color: themedColors.content,
                      width: "auto",
                      maxWidth: "150px",
                    },

                    "& .MuiInputBase-input": { fontSize: "14px", marginLeft: isMobile ? "0px" : "-8px"},
                    "& .MuiInputBase-input:focus": { boxShadow: "none"},
                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"} 
                  },
                },
                actionBar: ({ wrapperVariant }) => ({
                  actions: wrapperVariant === 'desktop' ? [] : ['clear', 'accept'],
                }),
                toolbar: {
                  className: 'first-picker'
                }
              }}
            />

            <DatePicker
              desktopModeMediaQuery={'@media (min-width: 1023px)'}
              minDate={startDate}
              value={endDate}
              onChange={handleEndDateChange}
              open={secondPickerOpened}
              onOpen={() => setSecondPickerOpened(true)}
              onClose={() => setSecondPickerOpened(false)}
              slots={{
                openPickerIcon: Calender,
                actionBar: CustomActionBar,
                toolbar: CustomToolbar
              }}
              format="MM/DD/YYYY"
              disableHighlightToday={true}
              sx={{ fontSize: "12px" }}
              slotProps={{
                inputAdornment: {
                  position: "start",
                  sx: {
                    "& .MuiIconButton-root svg path": {
                      stroke: themedColors.content
                    },
                  },
                },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#0079FF",
                      fontSize: "12px !important",
                    },
                  },
                },
                desktopPaper: {
                  sx: {
                    ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:focus":
                      {
                        backgroundColor: "#0079FF",
                        fontSize: "12px !important",
                      },
                  },
                },
                textField: {
                  sx: {
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                      color: themedColors.content,
                      width: "auto",
                      maxWidth: "150px",
                    },
                    "& .MuiInputBase-input": { fontSize: "14px", marginLeft: isMobile ? "0px" : "-8px"},
                    "& .MuiInputBase-input:focus": { boxShadow: "none"},
                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
                  },
                },
                actionBar: ({ wrapperVariant }) => ({
                  actions: wrapperVariant === 'desktop' ? [] : ['clear', 'accept'],
                }),
                toolbar: {
                  className: 'second-picker'
                }
              }}
            />
          </LocalizationProvider>
        </div>}

        {/*Show the apply button only when using custom dates*/}
        {!dateRange && <div className={'hidden tablet-size:block'}>
          <DefaultButton type='button' label='Apply'  className="flex-grow !text-base max-w-[168px] !hidden tablet-size:!flex h-9" onClick={handleApply} />
        </div>}
      </div>
  );
};

export default DatePickerLineChart;
