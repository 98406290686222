import { DeclineResponseReport, DeclineResponseReportData, SubCategory } from "../../../../../types/decline-response-report.types";

function transformData(input: DeclineResponseReportData[], categoryKey: string): DeclineResponseReport[] {
    const result = input.map((data: DeclineResponseReportData) => {
      const subCategoriesMap: { [reason: string]: SubCategory } = {};
  
      let totalCount = 0;
  
      data.decline_responses.forEach(({ response_title, response_count }) => {
        totalCount += response_count;
  
        if (subCategoriesMap[response_title]) {
          subCategoriesMap[response_title].count += response_count;
        } else {
          subCategoriesMap[response_title] = { reason: response_title, count: response_count };
        }
      });
  
      const subCategories = Object.values(subCategoriesMap);
  
      return {
        [categoryKey]: data.title,
        count: totalCount,
        subCategories
      };
    })

    return result
}

export default transformData;