import React, {useContext, useEffect} from "react";
import "../../../../css/EmptyReport.css"
import "../../../../assets/icons/EmptyCheckbox"
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DeclineResponse from "./DeclineResponse";
import { names } from "./utils/config";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { generateRangeConfigs } from "../../../../types/enums/RangeConfigs";
import CustomCardReportLayout from "../../../layout/CustomCardReportLayout/CustomCardReportLayout";
import { DeclineResponseReportClonedData } from "../../../../types/decline-response-report.types";
import { ConfigReport } from "../../../../types/report-config.types";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeclineResponseBase = () => {
    const [componentCount, setComponentCount] = React.useState<number>(0)
    const [clonedData, setClonedData] = React.useState<DeclineResponseReportClonedData[]>([{
        group_sequence: null,
        id: null,
        expand_level: null,
        start_date: null,
        end_date: null,
        collapse_data: null,
        heading_rows_data:null,
        data:null
    }])
    const [refreshKey, setRefreshKey] = React.useState(0);

    const userContext = useContext(UserContext)!;
    const { timezone } = userContext;

    useEffect(() => {
        try {
            const response = OdinForgeService.instance().getReportConfig(ReportName.DeclineResponseReport)
            response.then((result) => {
                if (!result) {
                    return
                }
                const configs = result.data.data
                if (configs.length === 0)
                    setComponentCount(1)
                else {
                    setComponentCount(configs.length)
                    let configArray: DeclineResponseReportClonedData[] = []
                    configs.forEach((element: ConfigReport, index: number) => {
                        let parsedData = JSON.parse(element.group_sequence);
                        let start_date = dayjs.utc(parsedData.start_date).tz(timezone);
                        let end_date = dayjs.utc(parsedData.end_date).tz(timezone);

                        configArray[index] = {
                            group_sequence: (JSON.parse(element.group_sequence).group_sequence).filter((value: string) => names.includes(value)),
                            id: element.id,
                            expand_level: (JSON.parse(element.group_sequence).expand_level?(JSON.parse(element.group_sequence).expand_level):'0'),
                            start_date: start_date,
                            end_date: end_date,
                            collapse_data: null,
                            heading_rows_data: null,
                            data: null,
                            type: element.type,
                            date_range: (JSON.parse(element.group_sequence).date_range?JSON.parse(element.group_sequence).date_range:0),
                            selectedFilters: (JSON.parse(element.group_sequence).selectedFilters?JSON.parse(element.group_sequence).selectedFilters:0),
                        }
                        if (configArray[index] && configArray[index].date_range != null && configArray[index].date_range !== 0) {
                            const { startDate, endDate } = (generateRangeConfigs(timezone))[configArray[index]?.date_range!];
                            configArray[index].start_date = dayjs.utc(startDate).tz(timezone);
                            configArray[index].end_date =  dayjs.utc(endDate).tz(timezone);
                        }
                    })
                    setClonedData(configArray)
                }
            })
        }catch (error){
            console.log('error', error)
        }
    },[refreshKey, timezone])

    function updateClonedData(data: DeclineResponseReportClonedData) {
        setComponentCount((prevCount) => prevCount + 1)
        let updatedClonedData = [...clonedData]
        updatedClonedData[componentCount] = data
        setClonedData(updatedClonedData)
    }

    function deleteClonedData(id: string | number | null | undefined) {
        setComponentCount((prevCount) => prevCount - 1)
        let updatedClonedData = clonedData.filter((item:DeclineResponseReportClonedData) => item.id !== id)
        setClonedData(updatedClonedData)
    }

    return (
        <>
        {[...Array(componentCount)].map((element, index:number) => {
            return (
                <div key={clonedData[index].id}>
                    <CustomCardReportLayout key={clonedData[index].id} id={(clonedData[index].id)}>
                        <DeclineResponse
                            names={names}
                            clonedData={clonedData[index]}
                            passData={updateClonedData}
                            passId={deleteClonedData}
                            setRefreshKey={setRefreshKey}
                            refreshKey={refreshKey}
                        />
                    </CustomCardReportLayout>
                </div>
                    )})}
    </>  
    );
}

export default DeclineResponseBase