// import { v4 as uuidv4 } from 'uuid';
// import { SalesPaceReportData } from '../types/sales-pace-report.types';

// export const transformData = (data: SalesPaceReportData) => {
//     if (Object.keys(data).length === 0) {
//       return {};
//     }
    
//     const transformObject = (obj: any, objName: any) => {
//       const transformed = { ...obj.stats, name: objName, id: uuidv4() };
//       Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
//         if (key !== "stats") {
//           if (typeof value === "object" && !Array.isArray(value)) {
//             transformed[key] = Object.entries(value)
//               .sort((a: any, b: any) =>(b[1].stats?.total_sales || 0) - (a[1].stats?.total_sales || 0))
//               .map(
//                 ([nestedKey, nestedValue]) => {
//                   if (nestedKey === "") {
//                     return transformObject(nestedValue, "NaN");
//                   }
//                   return transformObject(nestedValue, nestedKey);
//                 }
//               );
//           } else {
//             transformed[key] = value;
//           }
//         }
//       });
//       return transformed;
//     };
  
//     const result: { [key: string]: any } = {};
//     Object.entries(data).forEach(
//       ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
//         result[mainKey] = Object.entries(mainValue)
//           .sort((a: any, b: any) =>(b[1].stats?.total_sales || 0) - (a[1].stats?.total_sales || 0))
//           .map(
//             ([nestedKey, nestedValue]) => {
//               if (nestedKey === "") {
//                 return transformObject(nestedValue, "NaN");
//               }
//               return transformObject(nestedValue, nestedKey);
//             }
//           );
//       }
//     );
//     return result;
//   };

import { transformNames } from "./config";
import { v4 as uuidv4 } from 'uuid';

export const prepareDataForTransform = (data: any) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value)
              .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
              .map(
                ([nestedKey, nestedValue]) => {
                  if (nestedKey === "") {
                    return transformObject(nestedValue, "NaN");
                  }
                  return transformObject(nestedValue, nestedKey);
                }
              );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue)
          .sort((a: any, b: any) =>(b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
          .map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
      }
    );
    return result;
};

export function transformData(data: any): any[] | [] {
    const preparedData = prepareDataForTransform(data);
    function recursiveTransform(node: any): any[] {
        if (Array.isArray(node)) {
          // Process each item in the array and collect the results
          const transformedArray = node.flatMap(item => recursiveTransform(item));
          // Sort the array here based on your sorting criteria
          // For example, sort by stats.clicks in descending order
          transformedArray.sort((a, b) => (b.stats.total_sales || 0) - (a.stats.total_sales || 0));
          return transformedArray;
        } else if (typeof node === 'object' && node !== null) {
        const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

        if (nameKey) {
            const name = node.name ?? '---';

            const stats: any = {
              name,
              // total
              total_sales: node.total_sales || null,
              total_24_sales: node.total_24_sales || null,
              total_revenue: node.total_revenue || null,
              total_24_revenue: node.total_24_revenue || null,
              total_aov: node.total_aov || null,
              total_24_aov: node.total_24_aov || null,
              total_24_percentage: node.total_24_percentage || null,
              // s1
              s1_sales: node.s1_sales || null,
              s1_24_sales: node.s1_24_sales || null,
              s1_revenue: node.s1_revenue || null,
              s1_24_revenue: node.s1_24_revenue || null,
              s1_aov: node.s1_aov || null,
              s1_24_aov: node.s1_24_aov || null,
              s1_24_percentage: node.s1_24_percentage || null,
              // s2
              s2_sales: node.s2_sales || null,
              s2_24_sales: node.s2_24_sales || null,
              s2_revenue: node.s2_revenue || null,
              s2_24_revenue: node.s2_24_revenue || null,
              s2_aov: node.s2_aov || null,
              s2_24_aov: node.s2_24_aov || null,
              s2_24_percentage: node.s2_24_percentage || null,
              
              currency: node.currency || null,
            };

            const child_data: any[] = [];

            for (const key in node) {
              if (transformNames.includes(key) && key !== nameKey) {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              } else if (typeof node[key] === 'object') {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              }
            }

            child_data.sort((a, b) => (b.stats.total_sales || 0) - (a.stats.total_sales || 0));

            return [{
              name,
              stats,
              child_data,
            }];
        } else {
            const child_data: any[] = [];

            for (const key in node) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
            }

            child_data.sort((a, b) => (b.stats.total_sales || 0) - (a.stats.total_sales || 0));

            return child_data;
        }
        }
        return [];
    }

    return recursiveTransform(preparedData);
}