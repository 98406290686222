import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid',
    'Merchant Category',
    'Product Category',
    'Card Brand',
    'BIN',
    'Descriptor',
    'Descriptor Prefix',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid': 'c1',
    'Merchant Category': 'mid_category',
    'Product Category': 'product_type',
    'Card Brand': 'card_brand',
    'BIN': 'card_bin',
    'Descriptor': 'descriptor_value',
    'Descriptor Prefix': 'descriptor_prefix',
};

export const columnHeadings: {width: string; text:string; minWidth:number, hasSortField: boolean}[] = [
    { width: '120px', text: 'Initial Sales' , minWidth:0, hasSortField: true },
    { width: '120px', text: 'Initial Declines', minWidth:0, hasSortField: true },
    { width: '120px', text: 'Initial Approval %', minWidth:95, hasSortField: true },
    { width: '120px', text: 'Reproc Sales', minWidth:0, hasSortField: true },
    { width: '120px', text: 'Reproc Declines', minWidth:0, hasSortField: true  },
    { width: '150px', text: 'Reproc Approval %', minWidth:95, hasSortField: true  },
    { width: '120px', text: 'Rebill Sales', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Rebill Declines', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Rebill Approval %', minWidth:95, hasSortField: true },
    { width: '120px', text: 'Rebill Gross', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'CB #', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'CB %', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Refunds', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Refund Amount', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Partial Refunds', minWidth:0, hasSortField: true  },
    { width: '170px', text: 'Partial Refunds Amount', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Pending Txn', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Invalid Txn', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Canceled Orders', minWidth:0, hasSortField: true  },  
    { width: '120px', text: 'Gross Revenue', minWidth:0, hasSortField: true  },
    { width: '120px', text: 'Net Revenue', minWidth:0, hasSortField: true  },
]

export const fields = {
    "Initial Sales": "initial_sales",
    "Initial Declines": "initial_declines",
    "Initial Approval %": "initial_approval_rate",
    "Reproc Sales": "reproc_sales",
    "Reproc Declines": "reproc_declines",
    "Reproc Approval %": "reproc_approval_rate",
    "Rebill Sales": "rebill_sales",
    "Rebill Declines": "rebill_declines",
    "Rebill Approval %": "rebill_approval_rate",
    "Rebill Gross": "rebill_gross",
    "CB #": "charge_back",
    "CB %": "charge_back_percentage",
    "Refunds": "refunds",
    "Refund Amount": "refund_amount",
    "Partial Refunds": "partial_refunds",
    "Partial Refunds Amount": "partial_refunds_amount",
    "Pending Txn": "pending",
    "Invalid Txn": "invalid",
    "Canceled Orders": "canceled_orders",
    "Gross Revenue": "gross_revenue",
    "Net Revenue": "net_revenue",
} as const;

export const fieldsForCSV = {
    "Initial Sales": "initial_sales",
    "Initial Declines": "initial_declines",
    "Initial Approval %": "initial_approval_rate",
    "Reproc Sales": "reproc_sales",
    "Reproc Declines": "reproc_declines",
    "Reproc Approval %": "reproc_approval_rate",
    "Rebill Sales": "rebill_sales",
    "Rebill Declines": "rebill_declines",
    "Rebill Approval %": "rebill_approval_rate",
    "Rebill Gross": "rebill_gross",
    "CB #": "charge_back",
    "CB %": "charge_back_percentage",
    "Refunds": "refunds",
    "Refund Amount": "refund_amount",
    "Partial Refunds": "partial_refunds",
    "Partial Refunds Amount": "partial_refunds_amount",
    "Pending Txn": "pending",
    "Invalid Txn": "invalid",
    "Canceled Orders": "canceled_orders",
    "Gross Revenue": "gross_revenue",
    "Net Revenue": "net_revenue",
};