import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { FraudAnalysisReportClonedData, FraudAnalysisReportData, FraudAnalysisReportResponse, NestedLevels } from "./types/fraud-analysis-report.types";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { dateRangeListCustom, fieldsForCSV, generateRangeConfigs, getColumns, names, namesEnum, rangeConfigs } from "./utils/config";
import FilterBarFraudAnalysisReport from "../../../organism/filterbar/FilterBarFraudAnalysisReport";
import { determineNestingLevels, reorderLevels, updateExpandedState } from "../../../../helpers/collapsingFunctions";
import { prepareDataForTransform, transformData} from "./utils/transformData";
import { DataRangesParamsList } from "../../../../types/enums/DataRangesParamsList";
import ReportTable from "../../../organism/ReportTable/ReportTable";

dayjs.extend(utc);
dayjs.extend(timezone);


interface FraudAnalysisReportInterface {
    clonedData: FraudAnalysisReportClonedData
    passData: (data: FraudAnalysisReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}
const FraudAnalysisReport: React.FC<FraudAnalysisReportInterface> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(false);
    const [freezeButton, setFreezeButton] = React.useState(false)
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const [data, setData] = React.useState<FraudAnalysisReportData[]>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.FraudAnalysisReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);

    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<FraudAnalysisReportData[]>([]);

    const reportConfigValues = useRef({ reportName });

    const [loaderKey, setLoaderKey] = useState(0);

    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[names[0]]);
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'1');
    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});

    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
    const [globalFilter, setGlobalFilter] = useState('');
    const [isNoData, setIsNoData] = useState(false);

    useEffect(() => {
        if (data && collapse !== undefined) {
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
        groupings: JSON.stringify(groupings),
        collapse: collapse,
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || collapse !== clonedData['expand_level']
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate
        )) {
            previousValuesRef.current = {
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
                groupings: JSON.stringify(groupings),
                collapse: collapse,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate]);

    function generateParams() {
        const params = new URLSearchParams();
        
        if (dateRange === 0) {
            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        } else {
            const { startDate, endDate } = (generateRangeConfigs(timezone))[dateRange];

            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        }

        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
                superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
                tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }   
        
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })

        params.append('date_range', DataRangesParamsList[dateRangeListCustom[dateRange] as keyof typeof DataRangesParamsList])
        
        return params;
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
            filters: filters,
        }

        return dataToHash;
    }

    async function handleBlur(isRefresh?: boolean) {
        setTableLoading(true);
        if (isRefresh) {} else {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
        const params = generateParams()
        setData([]);
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            OdinForgeService.instance().getFraudAnalysisReportData(params, clonedReportId)
                // @ts-ignore
                .then(async (result: {data: FraudAnalysisReportResponse}) => {
                    setTableLoading(false);

                    if (result && result?.data){
                        const transformedData = transformData(result.data)
                        const csvData = prepareDataForTransform(result.data)
                        // @ts-ignore
                        setTransformedTableDataCSV(csvData)
                        setData(transformedData);

                        setNestedLevels(reorderLevels(determineNestingLevels(result.data, 1, 'currency', namesEnum)))

                        createHash(generateObjectToHash, result.data, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                        // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
            }
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            group_sequence: groupings,
            expand_level: collapse,
        }
        passData(dataToPass)
    }

    useEffect(() => {
        handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant, groupings]);

     const handleSearch = (isReset = false) => {
        if (searchTerm !== '') localStorage.setItem('searchTerm', searchTerm);

        if (!isReset) {
            setSearchTerm(''); 
            localStorage.removeItem('searchTerm');
            setGlobalFilter('')
            setIsNoData(false)
        } else {
            setGlobalFilter(searchTerm)
        }

        handleBlur(true)
    };

    return (
        <div>
            <div className="!p-6 tablet-size:!p-0">
            <FilterBarFraudAnalysisReport
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                title={ReportName.FraudAnalysisReport}
                //////////////////////// for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={fieldsForCSV}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={namesEnum}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                //////////////////////
                rangeConfigs={rangeConfigs}
                generateRangeConfigs={generateRangeConfigs}
                dateRangeListCustom={dateRangeListCustom}
                // dropdown options
                groupings={groupings}
                setGroupings={setGroupings}
                names={names}
                maxSelections={5}
                collapse={collapse}
                setCollapse={setCollapse}
                // SearchRowProps
                isSearch={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
            />
            </div>
            {
                data && data.length !== 0 && !isNoData ? (
                    <ReportTable
                        data={data} 
                        initialExpandedDepth={collapse}
                        globalFilter={globalFilter}
                        onNoData={setIsNoData} 
                        searchTerm={searchTerm}
                        groupingsCount={groupings.length}
                        levelNames={groupings}
                        getColumns={getColumns}
                    />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                groupings.length === 0 ? 
                                'noFilters' :
                                isNoData ? 'emptySearchResult' :
                                'noData'
                            }
                        />
                    </div>
                )
            }
            
        </div>
    )
}

export default FraudAnalysisReport