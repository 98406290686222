import React, { useContext } from "react";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import DatePickerLineChart from "../../UI/reportComponents/DatePickerLineChart";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarDuplicateCustomerReportProps<T1, T2, T3> {
    handleBlur: () => void;
    startDate: string | Dayjs;
    endDate: string | Dayjs;
    setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    dateRange: number;
    setDateRange: (newValue: number) => void;
    selectedGroupings: number;
    setTempCounter?(): number[];
    copyButton: boolean;
    freezeButton: boolean;
    buttonBackgroundColor?: string;
    iconFillColor?: string;
    showDelete: boolean;
    clonedReportId: string | number | null | undefined;
    setDeletePopup: (newValue: boolean) => void;
    passId: (id: string | number | null | undefined) => void;
    deletePopup: boolean;
    setButtonBackgroundColor?: (newValue: string) => void;
    setFreezeButton: (newValue: boolean) => void;
    setCopyButton: (newValue: boolean) => void;
    setIconFillColor?: (newValue: string) => void;
    clonedData: T1;
    generateConfigRequestBody: () => {
      filters: {
          date_range: number;
          start_date: string | null;
          end_date: string | null;
      };
      report_name: ReportName;
    };
    generateClonedData(reportId: number): void;
    title: string;
    // Additional props for CSV btn
    data: T2;
    fieldsForCSV?: { [key: string]: string };
    disabledCSV?: boolean;
    namesEnum?: { [key: string]: string };
    isCSV?: boolean;
    transformDataToCSVFormat?: (data: T3, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
      [key: string]: string | number;
    }[];
    disableFutureDates?: boolean;
    rangeConfigs?: {
      [key: string]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
      [key: number]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
  }
    dateRangeList?: string[];
    setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
    refreshKey?: number
    generateRangeConfigs?: (timezone: string) => {
      [key: string | number]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
    }
    dateRangeListCustom?: string[];
    // SearchRowProps
    isSearch?: boolean;
    searchTerm?: string;
    setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
    handleSearch?: (isReset?: boolean) => void
}

const FilterBarDuplicateCustomerReport = <T1,T2,T3,> ({
    handleBlur,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRange,
    setDateRange,
    selectedGroupings,
    setTempCounter,
    copyButton,
    freezeButton,
    buttonBackgroundColor,
    iconFillColor,
    showDelete,
    clonedReportId,
    setDeletePopup,
    passId,
    deletePopup,
    setButtonBackgroundColor,
    setFreezeButton,
    setCopyButton,
    setIconFillColor,
    clonedData,
    generateConfigRequestBody,
    generateClonedData,
    title,
    data,
    fieldsForCSV,
    disabledCSV,
    namesEnum,
    isCSV,
    transformDataToCSVFormat,
    disableFutureDates,
    rangeConfigs,
    dateRangeList,
    setRefreshKey,
    refreshKey,
    generateRangeConfigs,
    dateRangeListCustom,
    // SearchRowProps
    isSearch = false,
    searchTerm,
    setSearchTerm,
    handleSearch,
}: FilterBarDuplicateCustomerReportProps<T1,T2,T3>) => {
    const { themedColors } = useContext(ThemeContext)!;

    return (
        <>
            <div
                className={
                "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
                }
            >
                <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
                {title} 
                    </div>
                <DatePickerLineChart
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    getFilterData={handleBlur}
                    datesApplyButton={true}
                    customRangeConfigs={rangeConfigs}
                    customGenerateRangeConfigs={generateRangeConfigs}
                    dateRangeListCustom={dateRangeListCustom}
                />
            </div>

            <div
                className={
                "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
                }
            >
                <div className="max-w-[440px] mt-6">
                    {
                        isSearch && (
                        <SearchByRow
                            searchTerm={searchTerm ?? ""}
                            setSearchTerm={setSearchTerm ?? (() => {})}
                            handleSearch={handleSearch ?? (() => [])}
                        />
                        )
                    }
                </div>
                <div className="flex flex-wrap gap-3 items-end tablet-size:ml-auto">
                <CustomActionButtons
                    copyButton={copyButton}
                    freezeButton={freezeButton}
                    showDelete={showDelete}
                    clonedReportId={clonedReportId}
                    setDeletePopup={setDeletePopup}
                    passId={passId}
                    deletePopup={deletePopup}
                    setFreezeButton={setFreezeButton}
                    setCopyButton={setCopyButton}
                    clonedData={clonedData}
                    configRequestBody={generateConfigRequestBody()}
                    generateClonedData={generateClonedData}
                    data={data}
                    fieldsForCSV={fieldsForCSV}
                    reportName={title}
                    startDate={startDate}
                    endDate={endDate}
                    namesEnum={namesEnum}
                    disabledCSV={disabledCSV}
                    isCSV={isCSV}
                    transformDataToCSVFormat={transformDataToCSVFormat}
                    setRefreshKey={setRefreshKey}
                    refreshKey={refreshKey}
                />
                </div>
            </div>
            
        </>
    )
}

export default FilterBarDuplicateCustomerReport