import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Offer',
    'Subid',
    'Pubid',
];

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Offer': 'offer',
    'Subid': 'subid',
    'Pubid': 'pub_id',
};

export const firstRowData = [
    { id: 1, name: 'Canceled Orders', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 2, name: 'Cancellation %', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 3, name: 'Cancelled due to Refund', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 4, name: 'Blacklisted', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 5, name: 'Cannot Afford', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 6, name: 'Did not Order', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 7, name: 'Did not Receive', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 8, name: 'Doctor Advised', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 9, name: 'Does Not Like', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 10, name: 'Ethoca Alert', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 11, name: 'Possible Dispute', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 12, name: 'Product Not Working', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 13, name: 'Product Returned', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 14, name: 'Too Much Product', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 15, name: 'Was Not Aware of Terms', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 16, name: 'Customer Chargeback', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 17, name: 'Unknown Cancellation Reason', fontSize: "12px", width: "220px", padding: "16px 8px 16px 8px",   hasSortField: true }
];

export const fields = {
    "Canceled Orders": "canceled_orders",
    "Cancellation %": "cancelation_percentage",
    "Cancelled due to Refund": "cancelled_due_to_refund",
    "Blacklisted": "blacklist",
    "Cannot Afford": "cannot_afford",
    "Did not Order": "did_not_order",
    "Did not Receive": "did_not_receive",
    "Doctor Advised": "doctor_advised",
    "Does Not Like": "does_not_like",
    "Ethoca Alert": "ethoca_alerts",
    "Possible Dispute": "possible_dispute",
    "Product Not Working": "product_not_working",
    "Product Returned": "product_returned",
    "Too Much Product": "too_much_product",
    "Was Not Aware of Terms": "not_aware_of_trial_terms",
    "Customer Chargeback": "chargeback",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
} as const;

export const fieldsForCSV = {
    "Canceled Orders": "canceled_orders",
    "Cancellation %": "cancelation_percentage",
    "Cancelled due to Refund": "cancelled_due_to_refund",
    "Blacklisted": "blacklist",
    "Cannot Afford": "cannot_afford",
    "Did not Order": "did_not_order",
    "Did not Receive": "did_not_receive",
    "Doctor Advised": "doctor_advised",
    "Does Not Like": "does_not_like",
    "Ethoca Alert": "ethoca_alerts",
    "Possible Dispute": "possible_dispute",
    "Product Not Working": "product_not_working",
    "Product Returned": "product_returned",
    "Too Much Product": "too_much_product",
    "Was Not Aware of Terms": "not_aware_of_trial_terms",
    "Customer Chargeback": "chargeback",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
};
