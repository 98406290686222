import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
  'Affiliate': 'affiliate_name',
  'Merchant Account': 'mid_name',
  'Card Type': 'card_brand',
  'BIN': 'card_bin',
};

export const names = [
  'Affiliate',
  'Merchant Account',
  'Card Type',
  'BIN',
];

export const tableHeadingNames = {
    attempts: 'Attempts',
    successful: 'Success',
    failures: 'Failures',
    success_rate: 'Success Rate',
}

export const options = [
  { value: 'all', label: 'All' },
  { value: 'main_offer', label: 'Main Offer' },
  { value: 'upsell', label: 'Upsell' },
];

export const optionsAttemptTxsType = [
  { value: 'all', label: 'All' },
  { value: 'initial', label: 'Initial' },
  { value: 'reproc', label: 'Reproc' },
  { value: 'rebill', label: 'Rebill' },
];
