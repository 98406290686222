import React from 'react';

function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightMatch(text: string, searchTerm: string, theme: 'light' | 'dark' = 'light', size?: string): JSX.Element {
    if (!searchTerm || !text) {
        return <>{text}</>;
    }
    const escapedSearchTerm = escapeRegExp(searchTerm);
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = text.split(regex);
    return (
        <>
            {parts.map((part, index) => 
                regex.test(part) ? 
                <span 
                    key={index} 
                    style={{ backgroundColor: theme === 'light' ? '#87CEEB' : 'gray'}}
                    className={size ? `${size}` : ''}
                >
                    {part}
                </span> : 
                part
            )}
        </>
    );
}

export default highlightMatch;  