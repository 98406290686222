import React, { useContext, useEffect, useState } from 'react';
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import Tooltip from '@mui/material/Tooltip';
import SortedArrowAscending from '../../../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../../../assets/icons/SortedArrowDescending';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import ServerPaginationComponent from '../../../../molecules/PaginationComponent/ServerPaginationComponent';
import PaginationState from '../types/pagination.types';
import DefaultTableMessage from '../../../../organism/DefaultTableMessage/DefaultTableMessage';
import { CustomColumnMeta, DuplicateCustomerReportData } from '../types/duplicate-customer-report.types';
import { subTableColumnsMultiple, tableColumnsMultiple } from '../utils/config';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ExclamationMark from '../../../../../assets/icons/ExclamationMark';
import highlightMatch from '../../../../../helpers/highlightMatch';

interface DuplicateCustomerReportTableProps {
  data: DuplicateCustomerReportData[];
  setTransformedTableDataCSV: React.Dispatch<React.SetStateAction<DuplicateCustomerReportData[]>>
  onPageChange: (newPageIndex: number) => void
  onPageSizeChange: (newPageSize: number) => void
  paginationState: PaginationState;
  isSingleDate: boolean;
  searchTerm: string;
}

const DuplicateCustomerReportTable = ({
    data,
    setTransformedTableDataCSV,
    onPageChange,
    onPageSizeChange,
    paginationState,
    isSingleDate,
    searchTerm
  }: DuplicateCustomerReportTableProps) => {
    useEffect(() => {
      setTransformedTableDataCSV(data);
    }, [data, setTransformedTableDataCSV]);
    
    const filteredData = React.useMemo(() => data, [data]);
  
    const { themedColors, theme } = useContext(ThemeContext)!;
    const [hoveredTooltipId, setHoveredTooltipId] = useState<string | null>(null);
  
    const isMobile = useMediaQuery('(max-width: 1023px)')

    const columns = tableColumnsMultiple;
    const subCols = subTableColumnsMultiple;
  
    const table = useReactTable({
      columns,
      data: filteredData,
      debugTable: true,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      pageCount: paginationState.totalPages,
      rowCount: paginationState.totalItems,
      manualPagination: true,
      state: {
        pagination: {
          pageIndex: paginationState.currentPage,
          pageSize: paginationState.pageSize,
        },
      },
      // @ts-ignore
      onPaginationChange: ({ pageIndex, pageSize } : {pageIndex: number, pageSize: number}) => {
        onPageChange(pageIndex);
        onPageSizeChange(pageSize);
      },
      // onExpandedChange: setExpanded,
      getExpandedRowModel: getExpandedRowModel(),
    });
  
    const getCanNextPage = () => {
      return paginationState.totalPages > 0 && paginationState.totalPages >  paginationState.pageIndex;
    }
  
    const getCanPreviousPage = () => {
      return paginationState.totalPages > 0 && paginationState.pageIndex > 1;
    }
  
    const getLastPage = () => {
      return paginationState.totalPages;
    }

    // @ts-ignore
    const getCellValue = (subRow, accessorKey) => {
      if (accessorKey === 'hour') {
        return subRow.hour;
      }
    
      const keys = accessorKey.split('.');
      let value = subRow;
    
      for (const key of keys) {
        value = value?.[key];
        if (value === undefined) {
          return ''; // Если значение отсутствует, вернуть пустую строку
        }
      }
    
      return value;
    };
    
    return (
      <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
          {
            filteredData.length > 0 ? (
              <>
              <ScrollContainer
                className='scroll-container'
                hideScrollbars={false}
                mouseScroll={{ rubberBand: false }}
                style={{ cursor: 'default', borderRadius: !isMobile ? 8 : 0}}
              >
                <table
                className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
                style={{
                  minWidth: 500,
                }}
              >
                <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]'>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            const isLastInGroup = (header.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                            const isFontBold = (header.column.columnDef.meta as CustomColumnMeta)?.isFontBold;
                            const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';
                            const isAlignTextRight = (header.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                            const isToolTip = (header.column.columnDef.meta as CustomColumnMeta)?.isToolTip;
                            const tooltipMessage = (header.column.columnDef.meta as CustomColumnMeta)?.tooltipMessage;
      
                            return (
                              <th key={header.id} colSpan={header.colSpan} 
                                style={{
                                  borderRight: isLastInGroup ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                                  borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  color: themedColors.gray.bluish,
                                  fontWeight: isFontBold ? '600' : '500',
                                }}
                                className={`${headerBackground}`}
                              >
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                  className={`!text-xs font-inherit text-left ${
                                    header.column.getCanSort() ? 'cursor-pointer' : ''
                                  } 
                                    !h-[56px] !py-0 !px-2 flex justify-start items-center gap-2`}
                                    style={{
                                      justifyContent: isAlignTextRight ? 'right' : 'left',
                                      fontSize: '12px',
                                      lineHeight: '14.52px',
                                    }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: <span className='ml-2'><SortedArrowAscending /></span>,
                                    desc: <span className='ml-2'><SortedArrowDescending /></span>,
                                  }[header.column.getIsSorted() as string] ?? null}
                                  {
                                    isToolTip && (
                                        <Tooltip 
                                            classes={{ 
                                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal !bg-opacity-90 !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                             }}
                                            title={tooltipMessage}
                                            placement="right-end"
                                            onOpen={() => setHoveredTooltipId(header.id)}
                                            onClose={() => setHoveredTooltipId(null)} 
                                        >
                                            <span className='relative top-[-1px]'>
                                                <ExclamationMark isTipsHovered={hoveredTooltipId === header.id} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      )
                    )
                  })}
                </thead>
      
                <tbody>
                  {table.getRowModel().rows.map((row) => {      
                    return (
                      <React.Fragment key={row.id}>
                        <tr key={row.id} style={{ backgroundColor: 'inherit' }} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                          {row.getVisibleCells().map((cell) => {
                            const isLastInGroup = (cell.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                            const isAlignTextRight = (cell.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                            const isFirstColumn = (cell.column.columnDef.meta as CustomColumnMeta)?.isFirstColumn;
                            const isSearch = (cell.column.columnDef.meta as CustomColumnMeta)?.isSearch;
                            
                            function getIsTooltop(cell: Cell<DuplicateCustomerReportData, unknown>) {
                              const cellWidth = cell.column.getSize()
                              const charCount = Math.floor(cellWidth! / 8);
    
                              return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                            }

                            // get value of cell
                            const cellValue: string = cell?.getValue() !== null ? (cell?.getValue() as string | number)?.toString(): '';                            
    
                            return (
                              <td
                                key={cell.id}
                                id={cell.id}
                                className='!text-xs !h-[42px] !py-0 !px-2 !font-normal'
                                style={{
                                  borderBottom: row.getIsExpanded()
                                    ? ''
                                    :  theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                                  color: themedColors.content,
                                  maxWidth: '150px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)' : undefined,
                                  textAlign: isAlignTextRight ? 'right' : 'left'
                                }}
                              >
                                <Tooltip
                                  classes={{
                                    tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                    arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                  }}
                                  title={getIsTooltop(cell) as string}
                                  placement="left"
                                  arrow
                                >
                                  <span className='flex items-center' style={{justifyContent: isAlignTextRight ? 'right' : 'left', fontWeight: isFirstColumn ? '500' : '400',fontSize: '12px', }}>
                                  { isFirstColumn ? 
                                    (row.getIsExpanded() ? 
                                      <Icon icon={ChevronDownIcon} style={{color:themedColors.content}} onClick={isFirstColumn ? () => row.toggleExpanded() : undefined} className='cursor-pointer hover:!text-odin-light-blue-medium' /> : 
                                      <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} onClick={isFirstColumn ? () => row.toggleExpanded() : undefined} className='cursor-pointer hover:!text-odin-light-blue-medium' />) : 
                                    '' }
                                    {
                                      isSearch ? highlightMatch(cellValue, searchTerm, theme) : flexRender(cell.column.columnDef.cell,cell.getContext())
                                    }
                                  </span>
                                </Tooltip>
                              </td>
                            );
                          })}
                        </tr>

                        {row.getIsExpanded() &&
                          row.original.details?.map((subRow, index) => {
                            return (
                              <tr key={index} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F] bg-[#F5FAFF] dark:bg-[#202939]'>
                                {subCols && subCols.map((subColumn, index) => {
                                  const isAlignTextRight = (subColumn.meta as CustomColumnMeta)?.isAlignTextRight;

                                  const cellContext = {
                                    getValue: () => getCellValue(subRow, subColumn.accessorKey),
                                    row: {
                                      original: subRow,
                                    },
                                    column: subColumn,
                                  };

                                  return (
                                    <td
                                      key={index}
                                      className='!text-sm !h-[42px] !py-0 !font-normal'
                                      style={{
                                        borderBottom: theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                                        color: themedColors.content,
                                        maxWidth: 300,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        paddingLeft: subColumn.accessorKey === 'transacted_at_date' || subColumn.accessorKey === 'partner' ? 49 : 8,
                                        // borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #DFE9FE' : '1px solid #353E50' : '',
                                        paddingRight: 8,
                                        textAlign: isAlignTextRight ? 'right' : 'left',
                                        // display: 'flex',
                                        // justifyContent: isAlignTextRight ? 'flex-end' : 'flex-start',
                                      }}
                                    >
                                      <Tooltip
                                        classes={{
                                          tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                          arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                        }}
                                        title={""}
                                        placement="left-start"
                                        arrow
                                      >
                                        <span style={{fontSize: '12px',lineHeight: '16.94px', fontWeight: '400'}}>
                                          {flexRender(subColumn.cell as any, cellContext)}
                                        </span>
                                      </Tooltip>
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              </ScrollContainer>
  
            <ServerPaginationComponent
              firstPage={() => table.setPageIndex(0)}
              previousPage={() => table.previousPage()}
              getCanPreviousPage={() => getCanPreviousPage()}
              getCanNextPage={() => getCanNextPage()}
              nextPage={() => table.nextPage()}
              lastPage={() => table.setPageIndex(table.getPageCount() - 1)}
              getPageCount={() => table.getPageCount()}
              pageIndex={paginationState.pageIndex}
              setPageIndex={(pageIndex) => onPageChange(pageIndex)}
              setPageSize={(pageSize) => onPageSizeChange(pageSize)}
              pageSize={paginationState.pageSize}
              getRowCount={() => paginationState.totalItems}
              getLastPage={getLastPage}
            />
            </>
            
            ) : (
              <DefaultTableMessage
                state={'noData'}
              />
            )
          }
        
      </div>
    )
  }

export default DuplicateCustomerReportTable