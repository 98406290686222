import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
  'Merchant Account': 'mid_name',
  'Merchant Category': 'mid_category',
  'Card Brand': 'card_brand',
  'BIN': 'card_bin',
};

export const names = [
    'Merchant Account',
    'Merchant Category',
    'Card Brand',
    'BIN',
];

export const tableHeadingNames = {
    'Merchant Account': 'merchantAccount',
    'Merchant Category': 'merchantCategory',
    'Card Brand': 'cardBrand',
    'BIN': 'cardBin',
}


export interface MenuItem {
    id: string;
    label: string;
    checked?: boolean;
    subItems?: MenuItem[];
}

export const menuItems: MenuItem[] = [
    {
        id: 'decline_steps', 
        label: 'Steps', 
        subItems: [
            { id: 'step_1', label: 'Step 1' },
            { id: 'step_2', label: 'Step 2' },
        ]
    },
    {
        id: 'decline_transactions', 
        label: 'Transactions',
        subItems: [
            { id: 'initial_txns', label: 'Initial txns' },
            { id: 'rebill_txns', label: 'Rebill txns'},
            { id: 'reproc_txns', label: 'Reproc txns' },
        ]
    },
];


