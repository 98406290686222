import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid (C1)',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid (C1)': 'c1',
};

export const fields = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_into_orders",
    "Partials": "partials",
    "Partial rate": "partial_rate",
    "Partial Conversion rate": "partial_conv_rate",
    "Conversion rate": "conv_rate",
    "Sales": "sales",
    "Upsells": "upsells",
    "Take rate (Upsell rate)": "take_rate",
    "Declines": "declines",
    "Approval %": "approval",
} as const;

export const fieldsForCSV = {
    "Clicks": "clicks",
    "Order Clicks": "clicks_into_orders",
    "Partials": "partials",
    "Partial rate": "partial_rate",
    "Partial Conversion rate": "partial_conv_rate",
    "Conversion rate": "conv_rate",
    "Sales": "sales",
    "Upsells": "upsells",
    "Take rate (Upsell rate)": "take_rate",
    "Declines": "declines",
    "Approval %": "approval",
};

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().endOf('D').subtract(7, "d"), endDate: dayjs.tz().endOf('D')},
    4: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('D') },
    5: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
    6: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('D') },
    7: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().startOf('w').subtract(1, "s") },
    8: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
        3: { startDate: dayjs.tz().endOf('D').tz(timezone).subtract(7, "d").tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, 
        5: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        7: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().startOf('week').subtract(1, "second").tz(timezone) },
        8: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
        9: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
};