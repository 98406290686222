import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { ColumnDef } from "@tanstack/react-table";
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';

dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
  'Affiliate': 'affiliate_name',
  "Merchant Account": "mid_name",
  "Offer": "funnel_name", 
  'Pubid': 'pub_id',
};

export const names = [
    'Affiliate',
    "Merchant Account",
    "Offer",
    'Pubid',
];

export const transformNames = [
    'affiliate_name',
    "mid_name",
    'pub_id',
    "funnel_name",
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('D') },
    4: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
    5: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('D') },
    6: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
    7: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    8: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('D').subtract(1, "d").tz(timezone), endDate: dayjs.tz().endOf('D').subtract(1, "d").tz(timezone) },
        3: { startDate: dayjs.tz().startOf('w').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        4: { startDate: dayjs.tz().startOf('M').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        5: { startDate: dayjs.tz().startOf('y').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('w').subtract(1, "w").tz(timezone), endDate: dayjs.tz().endOf('w').subtract(1, "w").tz(timezone) },
        7: { startDate: dayjs.tz().startOf('M').subtract(1, "M").tz(timezone), endDate: dayjs.tz().startOf('M').subtract(1, "s").tz(timezone) },
        8: { startDate: dayjs.tz().startOf('y').subtract(1, "y").tz(timezone), endDate: dayjs.tz().startOf('y').subtract(1, "s").tz(timezone) }
    };
};

export const dateRangeListCustom = [
    "Custom Range",
    "Today",
    "Yesterday",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

export const fieldsForCSV = {
  "Alerts": "number_of_alerts",
  "Txns": "number_of_transactions",
  "Sales": "number_of_sales",
  "Main Offer Sales": "number_of_main_offer_sales",
  "Upsell Sales": "number_of_upsell_sales",
  "Initial Txns": "number_of_initial_transactions",
  "Rebill Txns": "number_of_rebill_transactions",
  "Reproc Txns": "number_of_reproc_transactions",
  "Ethoca Alerts": "number_of_ethoca_alerts",
  "RDR Alerts": "number_of_rdr_alerts",
  "Collab": "number_of_collab_alerts",
  "Chargebacks": "number_of_chargeback_alerts",
};

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
  return [
        {
          accessorKey: 'name',
          header: () => (
            <>
              Groupings
            </>
          ),
          cell: ({ row, getValue }) => {
            const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))

            return (
              <div
                style={{
                  paddingLeft: `${row.depth * 2}rem`,
                }}
              >
                <div className='flex gap-1 items-center flex-nowrap'>
                  {row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}
                      
                    >
                      {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                    </button>
                  ) : (
                    <span style={{ width: 16 }} />
                  )}{' '}
                  <span 
                    className='overflow-hidden text-ellipsis whitespace-nowrap'
                    style={{
                      maxWidth: `${(300 + ((Number(groupingsCount) + 1) * 8)) - ((row.depth + 1) * 40)}px`,
                    }}
                  >
                    <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                    {
                      hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                    } 
                    </span>
                    {highlightMatch(rowName, searchTerm, theme)}
                  </span>
                </div>
              </div>
            )
          },
          footer: props => props.column.id,
          meta: {
            isLastInGroup: true,
            isPinned: true,
            isGroupingColumn: true,
          },
          filterFn: 'includesStringSensitive'
        },
        {
          accessorFn: row => row.stats.number_of_alerts,
          id: 'Alerts',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
            isTooltip: true,
            tooltipMessage: 'Count of all Ethoca, RDR, Chargebacks, Collab marked txns'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_high_risk_orders,
          id: 'High Risk Orders',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'High Risk Orders',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of all high risk orders'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_transactions,
          id: 'Txns',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Txns',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of all txn attempts (successful & unsuccessful)'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_sales,
          id: 'Sales',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Sales',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of all successful sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_main_offer_sales,
          id: 'Main Offer Sales',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Main Offer Sales',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of all successful main offer sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_upsell_sales,
          id: 'Upsell Sales',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Upsell Sales',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of all successful upsell sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_initial_transactions,
          id: 'Initial Txns',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Initial Txns',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of Initial Sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_rebill_transactions,
          id: 'Rebill Txns',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Rebill Txns',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of Rebill Sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_reproc_transactions,
          id: 'Reproc Txns',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Reproc Txns',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of Reproc Sales'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_ethoca_alerts,
          id: 'Ethoca Alerts',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Ethoca Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of txns with Ethoca marked'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_rdr_alerts,
          id: 'RDR Alerts',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'RDR Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of txns with RDR marked'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_collab_alerts,
          id: 'Collab',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Collab',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of txns with Collab marked'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.number_of_chargeback_alerts,
          id: 'Chargebacks',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined ? '---' : Number(value).toLocaleString();
          },
          header: 'Chargebacks',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Count of txns with Chargeback marked'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.ethoca_alerts_to_all_transactions,
          id: 'Ethoca divided by all txns %',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined || value === '---' ? '---' : Number(value).toFixed(2) + '%';
          },
          header: 'Ethoca divided by all txns %',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Ethoca marked txns / all txns'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.rdr_alerts_to_all_transactions,
          id: 'RDR divided by all txns %',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined || value === '---' ? '---' : Number(value).toFixed(2) + '%';
          },
          header: 'RDR divided by all txns %',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'RDR marked txns / all txns'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.collab_alerts_to_all_transactions,
          id: 'Collab divided by all txns %',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined || value === '---' ? '---' : Number(value).toFixed(2) + '%';
          },
          header: 'Collab divided by all txns %',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Collab marked txns / all txns'
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: row => row.stats.chargeback_alerts_to_all_transactions,
          id: 'Chargebacks divided by all txns %',
          cell: ({ getValue }) => {
            const value = getValue();
            return value === null || value === undefined || value === '---' ? '---' : Number(value).toFixed(2) + '%';
          },
          header: 'Chargebacks divided by all txns %',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isTooltip: true,
            tooltipMessage: 'Chargeback marked txns / all txns'
          },
          sortingFn: numericStringSortingFn,
        },
      ]
}