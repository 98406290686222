import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { FraudAnalysisReportData } from "../types/fraud-analysis-report.types";

function transformDataToCSVFormat(
  data: FraudAnalysisReportData[],
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined
) {
  const result: { [key: string]: string | number }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = [...filters!] 

  function processItem(item: any, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string | number } = {
      number_of_alerts: item.number_of_alerts === null || item.number_of_alerts === undefined ? '---' : Number(item.number_of_alerts).toLocaleString(),
      number_of_transactions: item.number_of_transactions === null || item.number_of_transactions === undefined ? '---' : Number(item.number_of_transactions).toLocaleString(),
      number_of_sales: item.number_of_sales === null || item.number_of_sales === undefined ? '---' : Number(item.number_of_sales).toLocaleString(),
      number_of_main_offer_sales: item.number_of_main_offer_sales === null || item.number_of_main_offer_sales === undefined ? '---' : Number(item.number_of_main_offer_sales).toLocaleString(),
      number_of_upsell_sales: item.number_of_upsell_sales === null || item.number_of_upsell_sales === undefined ? '---' : Number(item.number_of_upsell_sales).toLocaleString(),
      number_of_initial_transactions: item.number_of_initial_transactions === null || item.number_of_initial_transactions === undefined ? '---' : Number(item.number_of_initial_transactions).toLocaleString(),
      number_of_rebill_transactions: item.number_of_rebill_transactions === null || item.number_of_rebill_transactions === undefined ? '---' : Number(item.number_of_rebill_transactions).toLocaleString(),
      number_of_reproc_transactions: item.number_of_reproc_transactions === null || item.number_of_reproc_transactions === undefined ? '---' : Number(item.number_of_reproc_transactions).toLocaleString(),
      number_of_ethoca_alerts: item.number_of_ethoca_alerts === null || item.number_of_ethoca_alerts === undefined ? '---' : Number(item.number_of_ethoca_alerts).toLocaleString(),
      number_of_rdr_alerts: item.number_of_rdr_alerts === null || item.number_of_rdr_alerts === undefined ? '---' : Number(item.number_of_rdr_alerts).toLocaleString(),
      number_of_collab_alerts: item.number_of_collab_alerts === null || item.number_of_collab_alerts === undefined ? '---' : Number(item.number_of_collab_alerts).toLocaleString(),
      number_of_chargeback_alerts: item.number_of_chargeback_alerts === null || item.number_of_chargeback_alerts === undefined ? '---' : Number(item.number_of_chargeback_alerts).toLocaleString(),
      ethoca_alerts_to_all_transactions: item.ethoca_alerts_to_all_transactions === null || item.ethoca_alerts_to_all_transactions === undefined ? '---' : Number(item.ethoca_alerts_to_all_transactions).toFixed(2) + '%',
      rdr_alerts_to_all_transactions: item.rdr_alerts_to_all_transactions === null || item.rdr_alerts_to_all_transactions === undefined ? '---' : Number(item.rdr_alerts_to_all_transactions).toFixed(2) + '%',
      collab_alerts_to_all_transactions: item.collab_alerts_to_all_transactions === null || item.collab_alerts_to_all_transactions === undefined ? '---' : Number(item.collab_alerts_to_all_transactions).toFixed(2) + '%',
      chargeback_alerts_to_all_transactions: item.chargeback_alerts_to_all_transactions === null || item.chargeback_alerts_to_all_transactions === undefined ? '---' : Number(item.chargeback_alerts_to_all_transactions).toFixed(2) + '%',
    };

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`${filtersList[level]}`] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';;

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: any) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  // Iterate through all potential top-level keys defined in namesEnum
  Object.values(namesEnum).forEach((topLevelKey) => {
    // @ts-ignore
    if (data[topLevelKey] && Array.isArray(data[topLevelKey])) {
      // @ts-ignore
      data[topLevelKey].forEach((item) => processItem(item));
    }
  });

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `${filtersList[level]}`
  );

  return result.map((item) => {
    const transformedItem: { [key: string]: string | number } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;
