function sanitizeInput(input: string) {
    // Define a regex to disallow dangerous characters
    const invalidChars = /['";]/g;

    // delete disallowed characters
    const sanitizedInput = input.replace(invalidChars, '');
    // Trim and return the sanitized input
    return sanitizedInput.trim();
}

export default sanitizeInput;